import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  Grid,
  ContentBox,
  HalvedContent,
  List,
  NonStretchedImage,
  Paragraphs
} from 'src/components';
import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-unterstuetzungskasse';
import subpageNavigations from 'src/data/subpage-navigations';

const BAVUnterstuetzungskasse = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.betrieblicheAltersvorsorge}
    activeNavItem={2}
    useParentPathname
  >
    <section>
      <Grid>
        <HalvedContent swapped breakpoint="xl">
          <BackgroundImage fluid={data.womanwithcup.childImageSharp.fluid} />
          <ContentBox
            background="gray-blue"
            topline={content.uebersichtSection.topline}
            headline={content.uebersichtSection.headline}
            align="right"
          />
        </HalvedContent>
        <div className="children-margin">
          <Paragraphs items={content.uebersichtSection.paragraphs} innerHTML />
        </div>
      </Grid>
    </section>
    <section>
      <ContentBox headline={content.funktioniertSection.headline} single>
        <Paragraphs items={content.funktioniertSection.paragraphs} />
        <NonStretchedImage
          fluid={data.unterstuetzungskasse.childImageSharp.fluid}
        />
      </ContentBox>
    </section>
    <section>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.manwithtablet.childImageSharp.fluid} />
        <ContentBox
          background="light-blue"
          headline={content.leistungenSection.headline}
        >
          <List items={content.leistungenSection.items} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <ContentBox
        background="light-blue"
        headline={content.vorteileSection.headline}
        single
      >
        <List items={content.vorteileSection.items} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default BAVUnterstuetzungskasse;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "laughing-woman-and-man-sit-on-the-same-step.jpg" }
    ) {
      ...image
    }
    unterstuetzungskasse: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "unterstuetzungskasse.jpg" }
    ) {
      ...image
    }
    manwithtablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-tablet.jpg" }
    ) {
      ...image
    }
    womanwithcup: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-wearing-green-shirt-and-hold-a-cup.jpg" }
    ) {
      ...image
    }
  }
`;
