const content = {
  title: 'bAV Unterstützungskasse',
  headvisualHeadline: 'Betriebliche Altersvorsorge (bAV) - Unterstützungskasse',
  uebersichtSection: {
    topline: 'Betriebliche Altersvorsorge',
    headline: 'Rückdeckende Unterstützungs&shy;kasse',
    paragraphs: [
      'Die <strong>Unterstützungskasse</strong> zählt zu den ältesten Durchführungswegen der betrieblichen Altersversorgung und eignet sich besonders für Gutverdiener, die ihren Lebensstandard im Alter aufrechterhalten wollen.',
      'Eine Unterstützungskasse ist eine rechtlich selbstständige Versorgungseinrichtung eines oder mehrerer Unternehmen, zumeist in der Rechtsform eines eingetragenen Vereins. Über die Unterstützungskasse haben Sie, als Arbeitgeber die Möglichkeit, ihre Mitarbeiter steuerbegünstigt in nahezu uneingeschränkter Höhe zu versorgen. Die Zahlungen an die Unterstützungskasse werden durch Sie geleistet - entweder direkt oder indirekt durch Entgeltumwandlung, finanziert also vom Bruttogehalt des Arbeitnehmers.'
    ]
  },
  funktioniertSection: {
    headline: 'Wie funktioniert eine rückdeckende Unterstützungskasse?',
    paragraphs: [
      '• Die Unterstützungskasse ist eine mit Sondervermögen ausgestattete rechtlich selbständige Einrichtung, die von einem oder mehreren Arbeitgebern getragen wird und ihren zugehörigen Leistungen ohne Rechtsanspruch gewährt. Der fehlende Rechtsanspruch ist aber nicht nachteilig für den Versorgungsberechtigten, da die Ansprüche der Versorgungsberechtigten durch das Betriebsrentengesetz gesichert sind.',
      '• Eine rückgedeckte Unterstützungskasse erhält von den an ihr beteiligten Unternehmen - den Trägerunternehmen - Zuwendungen, die sie zur Finanzierung von Rückdeckungsversicherungen, die auf das Leben der versorgungsberechtigten Arbeitnehmer abgeschlossen werden, verwendet.',
      '• Diese Rückdeckungsversicherungen und die daraus resultierenden Überschussanteile stellen das Vermögen einer rückgedeckten Unterstützungskasse dar, aus dem heraus die zugesagten Leistungen an die Versorgungsberechtigten erbracht werden.',
      '• Bei Eintritt des Versorgungsfalles erhält die Unterstützungskasse die Leistungen aus der Rückdeckungsversicherung und leitet diese nach Versteuerung direkt an den Versorgungsberechtigten weiter.',
      '• Versicherungsnehmer der Rückdeckungsversicherung ist die Unterstützungskasse, nicht das Trägerunternehmen. Dies und die Tatsache, dass die Unterstützungskasse das Versorgungsversprechen - wenn auch auf Veranlassung des Trägerunternehmens - erteilt, führt dazu, dass eine betriebliche Altersversorgung über eine Unterstützungskasse nicht in der Bilanz des Trägerunternehmens erscheint.'
    ]
  },
  leistungenSection: {
    headline: 'Welche Leistungen sind in einer Unterstützungskasse möglich?',
    items: [
      'Altersleistung ab dem 62. Lebensjahr',
      'Leistungen im Falle der Individualität',
      'Hinterbliebenenleistung'
    ]
  },
  vorteileSection: {
    headline: 'Ihre Vorteile auf einen Blick',
    items: [
      'Die rückgedeckte Unterstützungskasse ist bilanzneutral',
      'Kein Nachfinanzierungsrisiko',
      'Zuwendungen sind ohne Obergrenze steuerfrei – nur hoch angesetztes Leistungslimit',
      'Sicherer Schutz des GGF im Fall der Insolvenz',
      'Zuwendungen an die Unterstützungskasse sind in voller Höhe Betriebsausgaben',
      'Ideal mit anderen Durchführungswegen kombinierbar',
      'Aufnahme einzelner Gesellschafter-Geschäftsführer (GGF) möglich, keine Mindestanzahl von Personen erforderlich',
      'Versorgung auch im zweiten Dienstverhältnis möglich',
      'Einsparung bei Sozialversicherungsbeiträgen bei Entgeltumwandlung',
      'Kein Verwaltungsaufwand bei Versorgung über eine Gruppenunterstützungskasse',
      'Einfach – digital – online verwalten',
      'Einfache Abwicklung bei Liquidation der Firma, keine Zusatzkosten'
    ]
  }
};

export default content;
